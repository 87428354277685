export const CODIGOS_ARQUIVO_UPLOAD = {
	CodebarQuantidade: 1,
	Codebar: 2,
	CodigoProdutoQuantidade: 3,
	CodigoProduto: 4,
	CodebarQuantidadeLote: 5,
	CodigoProdutoQuantidadeLote: 6,
	CodebarQuantidadeLoteFabricacaoVencimento: 7,
	CodigoProdutoQuantidadeLoteFabricacaoVencimento: 8,
	CodigoProdutoSerial: 9,
}

export const HEADER_ARQUIVO_UPLOAD = {
	CodebarQuantidade: 'CodigoBarras;Quantidade',
	Codebar: 'CodigoBarras',
	CodigoProdutoQuantidade: 'CodigoProduto;Quantidade',
	CodigoProduto: 'CodigoProduto',
	CodebarQuantidadeLote: 'CodigoBarras;Quantidade;Lote',
	CodigoProdutoQuantidadeLote: 'CodigoProduto;Quantidade;Lote',
	CodebarQuantidadeLoteFabricacaoVencimento: 'CodigoBarras;Quantidade;Lote;DataFabricacao;DataVencimento',
	CodigoProdutoQuantidadeLoteFabricacaoVencimento: 'CodigoProduto;Quantidade;Lote;DataFabricacao;DataVencimento',
	CodigoProdutoSerial: 'CodigoProduto;CodigoBarras',
}

